import React from 'react';
import t from 'utils/Translator';
import theme from 'theme';
import EstimationIcon from './EstimationIcon';

export default function EstimationTable(props) {
    return (
        <div style={styles.container}>
            <div style={styles.rowContainer} className="row center-xs">
                <div style={styles.title} className="col-xs-12">
                    {t.get('titles.sizing')}
                </div>

                <EstimationIcon
                    SVGName="15"
                    label={t.get('labels.installationSize')}
                    value={`${props.estimation.nbSolarPanel} panneaux`}
                />
                <EstimationIcon
                    SVGName="solarPanel"
                    label={t.get('labels.installationPower')}
                    value={`${props.estimation.powerPanelCenter.toFixed(
                        2,
                    )} kWc`}
                />
                <EstimationIcon
                    SVGName="edf"
                    label={t.get('labels.energyPerYear')}
                    value={`${parseInt(
                        props.estimation.realProdBySolarPanel,
                    )} kWh`}
                />
            </div>

            <div style={styles.rowContainer} className="row center-xs">
                <div style={styles.title} className="col-xs-12">
                    {t.get('titles.results')}
                </div>
                {/*                <EstimationIcon
                    SVGName="coins"
                    label={t.get('labels.initialInvestment')}
                    value={'€'}
                />*/}
                <EstimationIcon
                    SVGName="euro"
                    label={t.get('labels.economy')}
                    value={`${props.estimation.realEconomy.toFixed(
                        0,
                    )} € (${props.estimation.pourcent.toFixed(0)}%)`}
                />
                <EstimationIcon
                    SVGName="state"
                    label={t.get('labels.governmentAid')}
                    value={`${props.estimation.governmentAid} €`}
                />
            </div>
        </div>
    );
}

const styles = {
    title: {
        textAlign: 'center',
        paddingBottom: 20,
        backgroundColor: theme.colors.white,
        fontSize: 30
    },
    rowContainer: {
        margin: 0,
        padding: '20px 0',
        backgroundColor: theme.colors.white,
    },
};
