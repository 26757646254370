const config = {
    // customisation
    env: 'habitatreference',
    color: '#EE7402',
    rgbPDF: {
        r: 0.933,
        g: 0.455,
        b: 0.008
    },
    header: {
        backgroundPosition: '',
	backgroundSize: 'contain',
        logoContainerPadding: 100,
        logo: {
            height: 0,
            width: 0,
            backgroundColor: 'transparent'
        },
        title: ''
    },
    name: 'Habitat Référence',
    custom: {
        // ajouter une clé pour ajouter une ligne
        1: '4, rue de Barcelone',
        2: '66670 Le Soler',
        3: '04 49 02 49 02'
    },
    mail: 'contact@habitat-reference.com',
    privacy: '',
    legal: 'https://www.habitat-reference.com/informations-legales',
    modalType: '', // 'achille' or ''
    hotline: '', // '09 00 00 00 00' or ''
    hotjar: {
        hjid: 0,
        hjsv: 0
    },
    googleAnalyticId: '',

    apiGoogle: {
        key: 'AIzaSyCRMyPFYFvqYqWSGAcoWBDNSSzy7ZliNxU',
        libraries: ['geometry', 'places'],
    },
    // internal urls
        sendFormUrl: 'https://votre-etude-personnalisee.habitat-reference.com/sendPDF',
        fetchBill: 'https://votre-etude-personnalisee.habitat-reference.com/estimated-invoice',
        fetchEstimation: 'https://votre-etude-personnalisee.habitat-reference.com/pre-calculate',

    // external urls
    achilleUrl: '',
};

export default config;

