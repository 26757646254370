import { useState } from 'react';
import Modal from 'react-modal';
import t from 'utils/Translator';
import config from 'config';
import storeImage from '../../../assets/images/store_screen.png';
import { ReactComponent as Cross } from '../../../assets/svgs/cross.svg';
import { ReactComponent as Mark } from '../../../assets/svgs/caution.svg';
import theme from 'theme';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '45%',
        textAlign: 'center',
    },
    overlay: { zIndex: 1000 },
};
export default function CustomModal(props) {
    const [modalIsOpen, setIsOpen] = useState(true);
    Modal.setAppElement('#root');

    const renderAchille = () => {
        return (
            <>
                <Mark fill={theme.colors.primary} height={50} />
                <Cross
                    onClick={closeModal}
                    style={{ position: 'absolute', right: 20 }}
                    fill={theme.colors.primary}
                    width="25px"
                    height="25px"
                />
                <p>{t.get('achille.1')}</p>
                <p>{t.get('achille.2')}</p>
                <p>
                    {t.get('achille.3')}
                    <a
                        rel="noreferrer"
                        style={{ color: theme.colors.primary }}
                        target="_blank"
                        href={`${config.achilleUrl}`}
                    >{`${config.achilleUrl}`}</a>
                </p>
                <img
                    style={{ boxShadow: '7px 8px 29px -5px #9E9E9E' }}
                    src={storeImage}
                    alt="achille"
                    width="60%"
                />
            </>
        );
    };

    const renderDefault = () => {
        return (
            <>
                <Mark fill={theme.colors.primary} height={50} />
                <Cross
                    onClick={closeModal}
                    style={{ position: 'absolute', right: 20 }}
                    fill={theme.colors.primary}
                    width="25px"
                    height="25px"
                />
                <p>{t.get('defaultModal.1')}</p>
            </>
        );
    };

    const renderError = () => {
        return (
            <>
                <Mark fill={theme.colors.primary} height={50} />
                <Cross
                    onClick={closeModal}
                    style={{ position: 'absolute', right: 20 }}
                    fill={theme.colors.primary}
                    width="25px"
                    height="25px"
                />
                <p>{t.get('errorModal')}</p>
            </>
        );
    };

    const renderContent = () => {
        switch (props.type) {
            case 'achille':
                return renderAchille();
            case 'error':
                return renderError();
            default:
                return renderDefault();
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            {renderContent()}
        </Modal>
    );
}
