import React from 'react';
import IconContainer from 'components/IconContainer';
import theme from 'theme';

export default function EstimationIcon({ SVGName, label, value, value02 }) {
    return (
        <div style={styles.container} className="col-xs-7 col-md-3">
            <div style={{ display: 'flex' }}>
                <IconContainer noBorder SVGName={SVGName} />
                <div style={styles.content}>
                    <div style={styles.label}>{label}</div>
                    <div style={{ color: theme.colors.primary }}>{value}</div>
                    <div style={styles.value02}>{value02}</div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        padding: 5,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: 6,
        paddingTop: 20
    },
    label: {
        fontSize: theme.font.size.S,
    },
    value02: {
        fontSize: theme.font.size.S,
        color: theme.colors.primary,
    },
};
