import React, { useState, useRef } from 'react';
import { sendForm } from 'utils/fetch';
import { Formik, Form as FormikForm } from 'formik';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Dots from '../../data/common/components/Dots';
import t from 'utils/Translator';
import CustomModal from '../../data/common/components/CustomModal';
import { getEnvValue } from 'utils';
import { initialValues } from './initialValues';
import { schema } from './schema';

export default function Form() {
    const [currentStep, setCurrentStep] = useState(1);
    const [error, setError] = useState(false);
    const [estimation, setEstimation] = useState({
        governmentAid: 0,
        irradiationRate: 0,
        maxSolarPanel: 0,
        nbMicroOnduleur: 0,
        nbSolarPanel: 0,
        pourcent: 0,
        powerPanelCenter: 0,
        realEconomy: 0,
        realProdBySolarPanel: 0,
        rt: 0,
    });

    const googlePlace = useRef();
    const polygonPos = useRef();

    const handleEstimation = data => {
        setEstimation(data);
    };

    const handleSubmit = form => {
        setError(false);
        return sendForm(form)
            .then(() => {
                setCurrentStep(4);
            })
            .catch(e => {
                console.log(e);
                setError(true);
            });
    };

    const renderStopModal = (values, resetForm) => {
        if (
            values.situation === 'tenant' ||
            values.buildingType === 'apartment'
        ) {
            return (
                <CustomModal
                    type={getEnvValue('modalType')}
                    onClose={resetForm}
                />
            );
        } else {
            return null;
        }
    };

    const renderSteps = () => {
        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        googlePlace={googlePlace}
                        polygonPos={polygonPos}
                        nextStep={() => setCurrentStep(currentStep + 1)}
                    />
                );
            case 2:
                return (
                    <Step2
                        nextStep={() => setCurrentStep(currentStep + 1)}
                        handleEstimation={handleEstimation}
                    />
                );
            case 3:
                return <Step3 />;
            case 4:
                return <Step4 estimation={estimation} />;
            default:
                return null;
        }
    };

    return (
        <>
            {error ? <CustomModal type="error" /> : null}
            <div style={styles.title}>{t.get(`titles.step${currentStep}`)}</div>
            <Dots currentStep={currentStep} setStep={setCurrentStep} />
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    await handleSubmit(values);
                    setSubmitting(false);
                }}
            >
                {({ resetForm, values }) => (
                    <FormikForm>
                        {renderStopModal(values, resetForm)}
                        {renderSteps()}
                    </FormikForm>
                )}
            </Formik>
        </>
    );
}

const styles = {
    title: {
        marginTop: 20,
        fontWeight: 'bold',
        fontSize: 28,
        textAlign: 'center',
    },
};
